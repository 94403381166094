import React from 'react';
import './styles/ClientsListEmpty.sass';
import Button from "../../../../UI/Button/Button";
import { getIsCreateClientModalOpened, modalState, ModalType } from "../../../../../store/Modal.recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IClientsListEmptyProps } from "./types/ClientsListEmpty.types";

const ClientsListEmpty = (props: IClientsListEmptyProps) => {
    const { message = "У вас пока нет добавленных клиентов" } = props;

    const setModalState = useSetRecoilState(modalState);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);

    const onButtonClick = (): void => {
        setModalState({
            type: ModalType.createClient,
            isOpened: !isCreateClientModalOpened,
        })
    }

    return (
        <div className="clients-list-empty">
            <p className="clients-list-empty__text">{message}</p>
            <Button title="Создать кабинет клиента" className="clients-list-empty__button" onClick={onButtonClick} />
        </div>
    )
}

export default ClientsListEmpty;