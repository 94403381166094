import axios from 'axios';
import { IApiDocumentsGetResponse } from "./documents.response";
import { IApiDocumentsGetRequest } from "./documents.requests";

class DocumentsApi {
    get = async (params?: IApiDocumentsGetRequest): Promise<IApiDocumentsGetResponse> => {
        const { data } = await axios.get(`/documents`, { params });

        return data;
    }

    download = async (params?: any): Promise<any> => {
        const { data } = await axios.get(`/documents/download`, {
            params,
        });

        return data;
    }

}

const documentsAPI = new DocumentsApi();
export default documentsAPI;