import { atom, selector } from "recoil";
import { IPaginatedList } from "../models/types";
import { DEFAULT_LIST_ITEMS_COUNT } from "../models/consts";

interface IClient {
    id: number;
    name: string;
}

export interface IClientsListItem {
    id: number;
    userId: number;
    companyId: number;
    name: string;
    inn: string;
    statistics: {
        views: number;
        resume: number;
        clicks: number;
        expanses: number;
    }
}

export interface IClientsList extends IPaginatedList {
    items: IClientsListItem[]
}

export interface IClientsListFilters {
    query?: string;
    startsAt?: string;
    endsAt?: string;
    page?: number;
}

interface IClientsState {
    currentClient?: IClient;
    list: IClientsList;
}

export const clientsState = atom<IClientsState>({
    key: "clientsState",
    default: {
        list: {
            count: 0,
            page: 1,
            limit: DEFAULT_LIST_ITEMS_COUNT,
            items: [],
            isFetching: false,
        }
    },
});

export const getClientsCurrentClient = selector<IClient | undefined>({
    key: 'clientsCurrentClient',
    get: ( { get } ) => {
        return get(clientsState).currentClient;
    },
})

export const getClientsIsLoggedToClient = selector<boolean>({
    key: 'clientsIsLoggedToClient',
    get: ( { get } ) => {
        const { currentClient } = get(clientsState);
        return currentClient !== undefined;
    },
});

export const getClientsList = selector<IClientsList>({
    key: 'clientsList',
    get: ( { get } ) => {
        return get(clientsState).list;
    },
})

export const getClientsIsExists = selector<boolean>({
    key: 'clientsIsExists',
    get: ( { get } ) => {
        return get(clientsState).list.count > 0;
    },
})