import { IGridDataCellProps } from "../../../UI/Grid/types/Grid.types";
import { IStatisticsListItem } from "../../../../store/Statistics.recoil";

export const statisticsDataCellFactory = (
    item: IStatisticsListItem,
    hiddenFields: string[] = [],
    cellRenderers: any = {}
): IGridDataCellProps[] => {
    const dataCells = Object.keys(item)
        .filter(( key: string ) => !hiddenFields.includes(key))
        .map(( key: string ) => {
            return {
                column: key,
                value: typeof cellRenderers[key] !== 'undefined' ? cellRenderers[key] : (item as any)[key]
            }
        });

    return [
        ...dataCells
    ]
}