import { atom, selector } from "recoil";

export interface IPartnerState {
    code?: string,
    user?: any,
    company?: any,
}

export const partnerState = atom<IPartnerState>({
    key: "partnerState",
    default: {
    }
})

export const getPartnerCode = selector<undefined|string>({
    key: "partnerCode",
    get: ({ get }) => {
        return get(partnerState).code;
    }
});
