import React, { Suspense, useCallback, useEffect, useState } from 'react';
import "../styles/Page.sass";
import "./styles/DocumentsPage.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import DocumentsListEmpty from "../../../components/Documents/DocumentsList/components/DocumentsListEmpty/DocumentsListEmpty";
import Switch from "../../../components/UI/Switch/Switch";
import { ISwitchItem } from "../../../components/UI/Switch/types/Switch.types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getClientsIsLoggedToClient } from "../../../store/Clients.recoil";
import { getCampaignsList } from "../../../store/Campaigns.recoil";
import DocumentsList from "../../../components/Documents/DocumentsList/DocumentsList";
import {
    DocumentsGroup,
    documentsState,
    DocumentsType,
    getDocumentsList,
    IDocumentsListFilters
} from "../../../store/Documents.recoil";
import DocumentsFilters from "../../../components/Documents/DocumentsFilters/DocumentsFilters";
import API from "../../../api";

const switchItems: ISwitchItem[] = [
    {
        id: DocumentsType.OWNER,
        label: "Мои документы"
    },
    {
        id: DocumentsType.CLIENTS,
        label: "Документы клиентов"
    }
]

const DocumentsPage = () => {
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const campaignsList = useRecoilValue(getCampaignsList);
    const documentsList = useRecoilValue(getDocumentsList);
    const [documentsType, setDocumentsType] = useState<string>(DocumentsType.OWNER);
    const [documentsGroup, setDocumentsGroup] = useState<string>(DocumentsGroup.INVOICE);

    const setDocumentsState = useSetRecoilState(documentsState);

    const fetchDocuments = useCallback((filters?: IDocumentsListFilters) => {
        API.documents.get({
            ...filters,
            type: documentsType,
            group: documentsGroup
        })
            .then(( response ) => {
                const { count, limit, next, page, prev, results } = response;

                setDocumentsState(( state ) => ({
                    ...state,
                    list: {
                        prev,
                        next,
                        count,
                        limit,
                        page,
                        items: results,
                    }
                }))
            })
            .catch(( err ) => {
                console.log(err);
            })
    }, [setDocumentsState, documentsType, documentsGroup]);

    const onFiltersChanged = ( filters: IDocumentsListFilters ) => {
        fetchDocuments(filters);
    }

    const onTabChanged = ( item: ISwitchItem ) => {
        setDocumentsType(item.id)
    }

    const onDocumentsGroupChanged = ( item: ISwitchItem ) => {
        setDocumentsGroup(item.id);
    }

    useEffect(() => {
        setDocumentTitle('Документы');
    }, []);

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    return (
        <div className="page documents-page">
            <PageTop className="documents-page__top">
                <PageTitle
                    className="documents-page__title"
                    title="Документы"
                />

                {!isLoggedToClient && (
                    <div className="documents-page__switch">
                        <Switch items={switchItems} onTabChanged={onTabChanged} />
                    </div>
                )}

                <DocumentsFilters
                    className="documents-page__filters"
                    onFiltersChanged={onFiltersChanged}
                />
            </PageTop>

            <Suspense>
                {campaignsList.items.length === 0 && (<DocumentsListEmpty />)}
                {campaignsList.items.length > 0 && (<DocumentsList list={documentsList} documentsType={documentsType} onGroupChanged={onDocumentsGroupChanged} />)}
            </Suspense>
        </div>
    );
}

export default DocumentsPage;
