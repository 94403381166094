import { atom } from "recoil";

export enum PaymentMethod {
    CARD = 'yookassa::bank_card',
    SBP = 'yookassa::sbp',
    B2B = 'yookassa::b2b_sberbank',
    INVOICE = 'invoice'
}

export interface IPaymentState {
    paymentMethod: PaymentMethod,
    amount: number;
    successUrl: string;
}

export const paymentState = atom<IPaymentState>({
    key: "paymentState",
    default: {
        paymentMethod: PaymentMethod.INVOICE,
        amount: 0,
        successUrl: window.location.origin + "/finances",
    }
})