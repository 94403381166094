import React from 'react';
import "./styles/DocumentsFilters.sass";
import { IDocumentsFiltersProps } from "./types/DocumentsFilters.types";
import Button from "../../UI/Button/Button";
import DateRangeInput from "../../UI/Inputs/DateRangeInput/DateRangeInput";

const DocumentsFilters = (props: IDocumentsFiltersProps) => {
    return (
        <div className="documents-filters">
            <DateRangeInput
                className="filter filter-date"
                label="Период"
            />

            <Button isSecondary className="filter-button" title="Поиск" />
        </div>
    )
}

export default DocumentsFilters;