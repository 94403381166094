import React, { useEffect } from 'react';
import "../styles/Page.sass";
import PageTop from "components/PageTop/PageTop";
import PageTitle from "components/UI/PageTitle/PageTitle";
import ProfileForm from "components/Profile/ProfileForm/ProfileForm";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import { useRecoilValue } from "recoil";
import { getClientsIsLoggedToClient } from "../../../store/Clients.recoil";

const ProfilePage = () => {
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const title = isLoggedToClient ? "Данные клиента" : "Основная информация";

    useEffect(() => {
        setDocumentTitle(title);
    }, [title]);

    return (
        <div className="page profile-page">
            <PageTop className="profile-page__top">
                <PageTitle
                    className="profile-page__title"
                    title={title}
                />
            </PageTop>

            <ProfileForm />
        </div>
    );
}

export default ProfilePage;
