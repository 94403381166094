import React from 'react';
import "./styles/Grid.sass";
import "./styles/Table.sass";
import { GridCellType, IGridDataCellProps, IGridHeaderCellProps, IGridProps } from "./types/Grid.types";
import GridTableCellActions from "./components/GridTableCellActions/GridTableCellActions";
import GridTableCellData from "./components/GridTableCellData/GridTableCellData";
import Pagination from "../Pagination/Pagination";

const Grid = ( props: IGridProps ) => {
    const { columnsTemplate, headers, data, count, page, limit, isFetching } = props;

    const getStartPosition = (): number => {
        return (page - 1) * limit + 1;
    }

    const getEndPosition = (): number => {
        return count < limit ? count : getStartPosition() + limit - 1;
    }

    return (
        <>
            <div className="grid">
                {!isFetching && (
                    <>
                        <div className="grid__top">
                            Показаны записи <strong>{getStartPosition()}-{getEndPosition()} из {count}</strong>
                        </div>

                        <div className="grid__table table">
                            <div className="table__header" style={{ gridTemplateColumns: columnsTemplate }}>
                                {headers.map(( item: IGridHeaderCellProps, i: number ) => (
                                    <div key={i} className="table__cell">{item.title}</div>
                                ))}
                            </div>
                            {data.length > 0 && (
                                <div className="table__body" style={{ gridTemplateColumns: columnsTemplate }}>
                                    {data.map(( item: IGridDataCellProps, i: number ) => (
                                        <div key={i} className="table__cell">
                                            {item.column === GridCellType.ACTIONS
                                                ? <GridTableCellActions actions={item.value}/>
                                                : <GridTableCellData item={item}/>
                                            }
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </>
                )}
                <div className="grid__bottom"></div>
            </div>
            {false && (<Pagination />)}
        </>
    );
}

export default Grid;
