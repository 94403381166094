import React from 'react';
import "./styles/FinancesHistoryCellDownload.sass";
import { IFinancesHistoryCellDownloadProps } from "./types/FinancesHistoryCellDownload.types";
import DownloadIcon from "../../../../UI/SvgIcons/DownloadIcon";
import API from "../../../../../api";

const ClientsListCellName = ( props: IFinancesHistoryCellDownloadProps ) => {
    const { file } = props;

    const handleClick = () => {
        API.documents.download({ id: file.id })
            .then(( response ) => {
                const blob = new Blob([response], { type: file.mime });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            })
            .catch(( error ) => {
                console.log(error);
            })
    }

    return (
        <div className="finances-history-cell-download">
            <button className="finances-history-cell-download__button" onClick={handleClick}>
                <DownloadIcon />
            </button>
        </div>
    )
}

export default ClientsListCellName;