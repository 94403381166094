import React, { ChangeEvent, useState } from 'react';
import "./styles/Dropdown.sass";
import DropdownTag from "./components/DropdownTag/DropdownTag";
import DropdownItem from "./components/DropdownItem/DropdownItem";
import classNames from "classnames";
import { DropdownItemValue, IDropdownItem, IDropdownProps } from "./types/Dropdown.types";
import { toggleArrayItem } from "../../../helpers/array.helper";
import ArrowDownIcon from "../SvgIcons/ArrowDownIcon";
import Hint from "../Hint/Hint";
import TextInput from "../Inputs/TextInput/TextInput";

const Dropdown = ( props: IDropdownProps ) => {
    const {
        className,
        isRequired = false,
        label,
        placeholder,
        hint,
        items,
        selectedValues = [],
        hasSearch = false,
        hasError = false,
        errorMessage,
        onSelectedItemsChanged
    } = props;

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isDropdownListScrolled, setIsDropdownListScrolled] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");

    const onDropdownToggleClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsDropdownListScrolled(false);
    }

    const onDropdownListScroll = ( e: any ) => {
        const { offsetHeight, scrollTop, scrollHeight } = e.target;
        setIsDropdownListScrolled(offsetHeight + scrollTop >= scrollHeight - 6);
    }

    const toggleDropdownItem = ( item: IDropdownItem ) => {
        const newValues = toggleArrayItem(selectedValues, item.value);
        setSelectedItems(newValues);
    }

    const onTargetingDropdownItemClick = ( item: IDropdownItem ) => {
        toggleDropdownItem(item);
    }

    const onSelectAllButtonClick = () => {
        if (items.length !== selectedValues.length) {
            items.filter((item) => !selectedValues.includes(item.value))
                .forEach((item) => toggleDropdownItem(item));
        } else {
            setSelectedItems([]);
        }
    }

    const onDropdownTagClick = ( item: IDropdownItem ) => {
        toggleDropdownItem(item);
    }

    const onDoneButtonClick = () => {
        setIsDropdownOpen(false);
    }

    const setSelectedItems = (values: DropdownItemValue[]): void => {
        if (typeof onSelectedItemsChanged === "function") {
            onSelectedItemsChanged([
                ...values
            ]);
        }
    }

    const isDropdownItemSelected = ( item: IDropdownItem ): boolean => {
        if  (typeof item.value === "string") {
            return selectedValues.includes(String(item.value));
        } else if (typeof item.value === "number") {
            return selectedValues.includes(Number(item.value));
        }  else {
            return selectedValues.includes(item.value);
        }
    }

    const isAllDropdownItemSelected = (): boolean => {
        return selectedValues.length === items.length;
    }

    const isSearchMatched = (label: string): boolean => {
        if (search.length > 0) {
            return label.toLowerCase().includes(search.toLowerCase());
        }

        return true;
    }

    const onSearchChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }

    return (
        <div className={classNames(
            "dropdown",
            className,
            { "is-opened": isDropdownOpen },
            { "has-search": hasSearch },
            { "has-error": hasError }
        )}>
            {label && (
                <div className={classNames("dropdown__label", { 'is-required': isRequired })}>
                    <div>{label}</div>
                    {hint && (<Hint text={hint}/>)}
                </div>
            )}
            <div className="dropdown__input">
                {placeholder && selectedValues.length === 0 && (
                    <div className="dropdown__placeholder" onClick={onDropdownToggleClick}>
                        {placeholder}
                    </div>
                )}

                {selectedValues.length > 0 && (
                    <div className="dropdown__tags">
                        {items
                            .filter(( item ) => isDropdownItemSelected(item))
                            .map(( item, index ) => (
                                <DropdownTag
                                    className="dropdown__tag"
                                    key={index}
                                    item={item}
                                    onTagClick={onDropdownTagClick}
                                />
                            ))
                        }
                    </div>
                )}

                <button type="button" className="dropdown__toggle" onClick={onDropdownToggleClick}>
                    <ArrowDownIcon/>
                </button>
            </div>

            {isDropdownOpen && (
                <>
                    {hasSearch && (
                        <TextInput
                            className="dropdown__search"
                            placeholder="Введите значение"
                            onChange={onSearchChanged}
                        />
                    )}
                    <div className={classNames("dropdown__list", { "is-list-scrolled": isDropdownListScrolled })}
                         onScroll={onDropdownListScroll}
                    >
                        <div className="dropdown__items">
                            {items
                                .filter(( item ) => isSearchMatched(item.label))
                                .map(( item, index ) => {
                                    return (
                                        <DropdownItem
                                            className="dropdown__item"
                                            key={index}
                                            item={item}
                                            isSelected={isDropdownItemSelected(item)}
                                            onItemClick={onTargetingDropdownItemClick}
                                        />
                                    )
                                })}
                        </div>
                        <div className="dropdown__item dropdown__item-last">
                            <DropdownItem
                                item={{ label: "Выбрать всё", value: "" }}
                                isSelected={isAllDropdownItemSelected()}
                                onItemClick={onSelectAllButtonClick}
                            />
                            <button type="button" onClick={onDoneButtonClick}>Готово</button>
                        </div>
                    </div>
                </>
            )}

            { hasError && errorMessage && (<div className="dropdown__error">{errorMessage}</div>) }
        </div>
    )
}

export default Dropdown;