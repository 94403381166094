import { atom, selector } from "recoil";
import { IPaginatedList } from "../models/types";
import { DEFAULT_LIST_ITEMS_COUNT } from "../models/consts";

export enum StatisticType {
    OWNER = 'owner',
    CLIENTS = 'clients'
}

export interface IStatisticsListItem {
    name: string;
    date: string;
    clicks: number;
    impressions: number
    cost: number;
    amount: number;
}

export interface IStatisticsList extends IPaginatedList {
    items: IStatisticsListItem[]
}

export interface IStatisticsListFilters {
    type?: string;
    query?: string;
    startsAt?: string;
    endsAt?: string;
    page?: number;
}

interface IStatisticsState {
    list: IStatisticsList;
}

export const statisticsState = atom<IStatisticsState>({
    key: "statisticsState",
    default: {
        list: {
            count: 0,
            page: 1,
            limit: DEFAULT_LIST_ITEMS_COUNT,
            items: [],
            isFetching: false,
        }
    },
});

export const getStatisticsList = selector<IStatisticsList>({
    key: 'statisticsList',
    get: ( { get } ) => {
        return get(statisticsState).list;
    },
})