import React, { useEffect, useState } from 'react';
import "../styles/Page.sass";
import "./styles/CampaignViewPage.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../api";
import Button from "../../../components/UI/Button/Button";
import CampaignView from "../../../components/Campaign/CampaignView/CampaignView";
import GoBackIcon from "../../../components/UI/SvgIcons/GoBackIcon";
import { ICampaign } from "../../../api/services/campaigns/campaigns.response";
import { useSetRecoilState } from "recoil";
import { toastState } from "../../../store/Toast.recoil";
import { ToastType } from "../../../components/UI/Toast/types/Toast.types";

const CampaignViewPage = () => {
    const navigate = useNavigate();
    const { id: campaignId } = useParams();
    const [campaign, setCampaign] = useState<ICampaign | undefined>();
    const [editButtonTitle, setEditButtonTitle] = useState<string>("Отправить на модерацию");
    const setToastState = useSetRecoilState(toastState);

    const onGoBackButtonClick = (): void => {
        return navigate('/campaigns');
    }

    const getEditButtonTitle = (campaignStatus: string): string => {
        switch (campaignStatus) {
            case 'draft':
                return "Отправить на модерацию";
            case 'active':
                return "Остановить";
            case 'paused':
                return "Возобновить";
        }

        return campaignStatus;
    }

    const onEditButtonClick = (): void => {
        return navigate(`/campaigns/${campaignId}/edit`);
    }

    const onStatusButtonClick = () => {
        const id = Number(campaignId);

        if (campaign?.status === "draft") {
            API.campaigns.moderate(id)
                .then(( { data } ) => {
                    setCampaign(data);
                })
                .catch(( { response } ) => {
                    const message = response?.data?.message || "Произошла ошибка. Попробуйте позже.";
                    setToastState({
                        type: ToastType.ERROR,
                        message,
                        isShown: true
                    })
                })
        }

        if (campaign?.status === "active") {
            API.campaigns.pause(id)
                .then(( { data } ) => {
                    setCampaign(data);
                })
                .catch(( err: Error ) => {
                    console.log(err);
                })
        }

        if (campaign?.status === "paused") {
            API.campaigns.resume(id)
                .then(( { data } ) => {
                    setCampaign(data);
                })
                .catch(( err: Error ) => {
                    console.log(err);
                })
        }

        if (campaign?.status === "closed") {

        }

        if (campaign?.status === "canceled") {

        }

        if (campaign?.status === "deleted") {

        }

        if (campaign?.status === "waiting") {

        }
    }

    useEffect(() => {
        setDocumentTitle('Просмотр рекламной кампании');
    }, []);

    useEffect(() => {
        if (campaignId) {
            API.campaigns.view(campaignId)
                .then(( { data } ) => {
                    setCampaign(data);
                })
        }
    }, [campaignId]);

    useEffect(() => {
        if (!campaign) return;

        setEditButtonTitle(getEditButtonTitle(campaign.status));
    }, [campaign]);

    return (
        <div className="page campaign-view-page">
            {campaign && (
                <>
                    <PageTop className="campaign-view-page__top">
                        <button className="campaign-view-page__back" onClick={onGoBackButtonClick}>
                            <GoBackIcon/>
                        </button>

                        <PageTitle
                            className="campaign-view-page__title"
                            title={campaign.name}
                        />

                        <div className="campaign-view-page__actions">
                            <Button title="Редактировать" onClick={onEditButtonClick}/>
                            {['draft', 'active', 'paused'].includes(campaign.status) && (
                                <Button isWhite title={editButtonTitle} onClick={onStatusButtonClick}/>
                            )}
                        </div>
                    </PageTop>

                    <CampaignView campaign={campaign}/>
                </>
            )}
        </div>
    );
}

export default CampaignViewPage;
