import React from 'react';
import "./styles/DropdownTag.sass";
import { IDropdownTagProps } from "./types/DropdownTag.types";
import CrossIcon from "../../../SvgIcons/CrossIcon";

const DropdownTag = ( props: IDropdownTagProps ) => {
    const { item, onTagClick } = props;

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (typeof onTagClick === "function") {
            onTagClick(item);
        }
    }

    return (
        <div className="dropdown-tag" onClick={onClick}>
            <div className="dropdown-tag__label">{item.label}</div>
            <button type="button" className="dropdown-tag__close">
                <CrossIcon />
            </button>
        </div>
    )
}

export default DropdownTag;