import axios from 'axios';
import { IApiPartnerGetResponse } from "./partner.response";

class PartnerApi {
    get = async (): Promise<IApiPartnerGetResponse> => {
        const response = await axios.get('/partner');

        return response.data;
    }
}

const clientAPI = new PartnerApi();
export default clientAPI;