export const getFrontendUrl = (): string => {
    return process.env.REACT_APP_FRONTEND_URL || 'https://vtochku.online';
}

export const redirectToHome = () => {
    window.location.replace(getFrontendUrl());
}

export const redirectToLogout = () => {
    const redirectUrl = getFrontendUrl() + '/auth/logout';
    window.location.replace(redirectUrl);
}