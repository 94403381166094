import React from 'react';
import './styles/ProfileSidebar.sass';
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Modal from "../../UI/Modal/Modal";
import ProfileSidebarItem from "./ProfileSidebarItem/ProfileSidebarItem";
import Button from "../../UI/Button/Button";
import { modalState } from "../../../store/Modal.recoil";
import { removeAccessToken } from "../../../helpers/accessToken.helpers";
import { redirectToLogout } from "../../../helpers/redirect.helper";
import { companyState } from "../../../store/Company.recoil";
import { getUserFullName, userState } from "../../../store/User.recoil";
import { clientsState, getClientsIsLoggedToClient } from "../../../store/Clients.recoil";
import { profileCompanyFactory, profilePersonFactory, profileState } from "../../../store/Profile.recoil";
import { getPartnerCode } from "../../../store/Partner.recoil";

const ProfileSidebar = (props: any) => {
    const user = useRecoilValue(userState);
    const company = useRecoilValue(companyState);
    const userFullName = useRecoilValue(getUserFullName);
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const partnerCode = useRecoilValue(getPartnerCode);

    const setModalState = useSetRecoilState(modalState);
    const setClientsState = useSetRecoilState(clientsState);
    const setProfileState = useSetRecoilState(profileState);

    const navigate = useNavigate();
    const onProfileEditClick = () => {
        setModalState({
            type: null,
            isOpened: false
        });

        if (isLoggedToClient) {
            setProfileState((state) => {
                return {
                    ...state,
                    person: profilePersonFactory(user),
                    company: profileCompanyFactory(company),
                }
            });
            setClientsState((state) => {
                return {
                    ...state,
                    currentClient: undefined
                }
            })
        }

        navigate("/profile");
    }

    const onLogoutClick = () => {
        removeAccessToken();
        redirectToLogout();
    }

    return (
        <Modal justifyContent="right">
            <div className="profile-sidebar">
                <div className="profile-sidebar__header">
                    <div className="profile-sidebar__title">Профиль</div>
                    {userFullName && (
                        <div className="profile-sidebar__name">{userFullName}</div>
                    )}
                </div>

                <div className="profile-sidebar__body">
                    <ProfileSidebarItem label="ID партнера" value={partnerCode} />
                    <ProfileSidebarItem label="Наименование компании" value={company?.legalName} />
                    <ProfileSidebarItem label="E-mail" value={user?.email} />
                </div>

                <div className="profile-sidebar__actions">
                    <Button title="Редактировать" onClick={onProfileEditClick} />
                    <Button isSecondary title="Выход" onClick={onLogoutClick} />
                </div>
            </div>
        </Modal>
    );
};

export default ProfileSidebar;