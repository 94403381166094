import { HTMLAttributes } from "react";
import { ICampaignContent } from "../../../../api/services/campaigns/campaigns.response";

export enum TAB {
    COMMON = 'common',
    TARGETING = 'targeting',
    BUDGET = 'budget',
}

export interface ICampaignFormTab {
    id: TAB;
    title: string;
    group: string;
}

export enum DeviceType {
    TV = 'tv',
    PHONE = 'phone'
}

export enum LimitType {
    AsBudget = 'ad-budget',
    AsLimit = 'as-limit'
}

export interface ICampaignFormData {
    name: string;
    startsAt: string;
    endsAt: string;
    targetLink: string;
    kktuCodes: string[];
    content?: ICampaignContent;
    regions: number[];
    intervals: number[];
    genres: string[];
    devices: string[];
    age: string[];
    sex: number[];
    budgetDaily: number;
    budgetTotal: number;
    maxImpressionsPerDay: number;
    maxImpressions: number;
    cost: number;
    dailyImpressionsPerPerson: number;
}

export interface ICampaignContentData {
    file: File,
    duration: number;
}

export interface ICampaignFormProps {
    campaignId?: string;
}

export interface ICampaignFormTabData extends HTMLAttributes<HTMLDivElement> {
    values: ICampaignFormData;
    onTabDataChanged: ( data: any ) => void;
}