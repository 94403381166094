import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const StatsIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.6785 3.34872C12.9705 4.16372 12.6885 9.28872 13.5115 10.1127C14.3345 10.9347 19.2795 10.5187 20.4675 9.58372C23.3255 7.33272 15.9385 0.746719 13.6785 3.34872Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.1372 13.7899C19.2212 14.8739 16.3472 21.0539 10.6512 21.0539C6.39722 21.0539 2.94922 17.6059 2.94922 13.3529C2.94922 8.05293 8.17822 4.66293 9.67722 6.16193C10.5402 7.02493 9.56822 11.0859 11.1162 12.6349C12.6642 14.1839 17.0532 12.7059 18.1372 13.7899Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default StatsIcon;