import React, { Suspense, useEffect } from 'react';
import "../styles/Page.sass";
import "./styles/ClientsPage.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import ClientsFilters from "../../../components/Client/ClientsFilters/ClientsFilters";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getIsCreateClientModalOpened, modalState, ModalType } from "../../../store/Modal.recoil";
import ClientsList from "../../../components/Client/ClientsList/ClientsList";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import ClientsPlates from "../../../components/Client/ClientsPlates/ClientsPlates";
import { clientsState, getClientsList, IClientsListFilters } from "../../../store/Clients.recoil";
import ClientsListEmpty from "../../../components/Client/ClientsList/components/ClientsListEmpty/ClientsListEmpty";
import API from "../../../api";
import { getUserClientsCount } from "../../../store/User.recoil";

const ClientsPage = () => {
    const clientsList = useRecoilValue(getClientsList);
    const setClientsState = useSetRecoilState(clientsState);
    const clientsCount = useRecoilValue(getUserClientsCount);

    useEffect(() => {
        setDocumentTitle('Клиенты');
    }, []);

    const setModalState = useSetRecoilState(modalState);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);

    const onButtonClick = () => {
        setModalState({
            type: ModalType.createClient,
            isOpened: !isCreateClientModalOpened,
        })
    }

    const onFiltersChanged = ( filters: IClientsListFilters ) => {
        API.clients.list(filters)
            .then(( response ) => {
                const { count, limit, next, page, prev, results } = response;

                setClientsState((state) => ({
                    ...state,
                    list: {
                        prev,
                        next,
                        count,
                        limit,
                        page,
                        items: results
                    }
                }))
            })
            .catch(( err ) => {
                console.log(err);
            })
    }

    return (
        <div className="page clients-page">
            <PageTop className="clients-page__top">
                <PageTitle
                    className="clients-page__title"
                    title="Клиенты"
                    hasButton
                    buttonTitle="Создать кабинет клиента"
                    buttonAction={onButtonClick}
                />

                <ClientsFilters
                    className="clients-page__filters"
                    onFiltersChanged={onFiltersChanged}
                />
            </PageTop>

            <Suspense>
                {clientsCount === 0 && (<ClientsListEmpty />)}
                {clientsCount > 0 && clientsList.items.length === 0 && (
                    <ClientsListEmpty message="По введённым критериям клиенты не найдены" />
                )}
                {clientsCount > 0 && clientsList.items.length > 0 && (
                    <>
                        <ClientsList list={clientsList}/>
                        <ClientsPlates list={clientsList}/>
                    </>
                )}
            </Suspense>
        </div>
    );
}

export default ClientsPage;
