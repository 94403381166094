import React, { MouseEvent } from 'react';
import './styles/Sidebar.sass';
import { ISidebarItemProps, ISidebarProps } from "./types/Sidebar.types";
import { NavLink, NavLinkRenderProps, useNavigate } from "react-router-dom";
import StarIcon from "../UI/SvgIcons/StarIcon";
import ClientsIcon from "../UI/SvgIcons/ClientsIcon";
import LockOpenedIcon from "../UI/SvgIcons/LockOpenedIcon";
import WalletIcon from "../UI/SvgIcons/WalletIcon";
import UserIcon from "../UI/SvgIcons/UserIcon";
import MessageIcon from "../UI/SvgIcons/MessageIcon";
import DocumentsIcon from "../UI/SvgIcons/DocumentsIcon";
import ReturnIcon from "../UI/SvgIcons/ReturnIcon";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { clientsState, getClientsIsLoggedToClient } from "../../store/Clients.recoil";
import { modalState, ModalType } from "../../store/Modal.recoil";
import {
    getProfilePersonBalance,
    profileCompanyFactory,
    profilePersonFactory,
    profileState
} from "../../store/Profile.recoil";
import { getUserFullName, userState } from "../../store/User.recoil";
import { companyState } from "../../store/Company.recoil";
import classNames from "classnames";
import WalletFilledIcon from "../UI/SvgIcons/WalletFilledIcon";
import { prettifyMoneyValue } from "../../helpers/prettifier.helpers";
import ProfileIcon from "../UI/SvgIcons/ProfileIcon";
import StatsIcon from "../UI/SvgIcons/StatsIcon";
import API from "../../api";

const Sidebar = (props: ISidebarProps) => {
    const { className, withFooter = false } = props;

    const user = useRecoilValue(userState);
    const company = useRecoilValue(companyState);
    const userFullName = useRecoilValue(getUserFullName);
    const profileBalance = useRecoilValue(getProfilePersonBalance);

    const setClientsState = useSetRecoilState(clientsState);
    const setProfileState = useSetRecoilState(profileState);
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const setModalState = useSetRecoilState(modalState);

    const sidebarLinks: ISidebarItemProps[] = [
        {
            icon: <UserIcon/>,
            title: isLoggedToClient ? "Данные клиента" : "Профиль",
            href: "/profile",
            isVisible: true,
        },
        {
            icon: <StarIcon/>,
            title: isLoggedToClient ? "Рекламные кампании" : "Мои рекламные кампании",
            href: "/campaigns",
            isVisible: true,
        },
        {
            icon: <ClientsIcon/>,
            title: "Клиенты",
            href: "/clients",
            isVisible: !isLoggedToClient,
        },
        {
            icon: <LockOpenedIcon/>,
            title: "Доступы",
            href: "/access",
            isVisible: !isLoggedToClient,
        },
        {
            icon: <WalletIcon/>,
            title: isLoggedToClient ? "Баланс" : "Пополнение бюджета",
            href: "/finances",
            isVisible: true,
        },
        {
            icon: <StatsIcon/>,
            title: "Статистика",
            href: "/statistics",
            isVisible: true,
        },
        {
            icon: <DocumentsIcon/>,
            title: "Документы",
            href: "/documents",
            isVisible: true,
        }
    ]

    const getNavLinkClass = ( { isActive, isPending }: NavLinkRenderProps ): string => {
        return "sidebar__link link " + (isActive ? "active" : isPending ? "pending" : "");
    }

    const onSupportButtonClick = (e: MouseEvent): void => {
        e.preventDefault();

        setModalState({
            type: ModalType.support,
            isOpened: true
        })
    }

    const navigate = useNavigate();
    const onReturnButtonClick = (): void => {
        API.removeExtraUser();

        navigate('/clients');

        setProfileState((state) => {
            return {
                ...state,
                person: profilePersonFactory(user),
                company: profileCompanyFactory(company),
            }
        });

        setClientsState((state) => {
            return {
                ...state,
                currentClient: undefined
            }
        });
    }

    return (
        <div className={classNames("sidebar", className)}>
            <nav className="sidebar__nav">
                {isLoggedToClient && (<div className="sidebar__item">
                    <button type="button" className="sidebar__button" onClick={onReturnButtonClick}>
                        <span className="sidebar__icon"><ReturnIcon/></span>
                        <span className="sidebar__title">Вернуться в свой кабинет</span>
                    </button>
                </div>)}

                {sidebarLinks.map(( item: ISidebarItemProps, i: number ) => item.isVisible && (
                    <div className="sidebar__item" key={i}>
                        <NavLink to={item.href} className={getNavLinkClass}>
                            {item.icon && <span className="sidebar__icon">{item.icon}</span>}
                            <span className="sidebar__title">{item.title}</span>
                        </NavLink>
                    </div>
                ))}

                <div className="sidebar__item">
                    <button className="sidebar__link" onClick={onSupportButtonClick}>
                        <span className="sidebar__icon"><MessageIcon /></span>
                        <span className="sidebar__title">Поддержка</span>
                    </button>
                </div>
            </nav>

            {withFooter && (
                <div className="sidebar__footer">
                    <div className="sidebar__divider"></div>

                    <div className="sidebar__item">
                        <span className="sidebar__icon"><WalletFilledIcon /></span>
                        <span className="sidebar__title" dangerouslySetInnerHTML={{__html: prettifyMoneyValue(profileBalance) }}></span>
                    </div>

                    <div className="sidebar__item">
                        <span className="sidebar__icon"><ProfileIcon /></span>
                        <span className="sidebar__title">{userFullName}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Sidebar;