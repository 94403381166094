import React, { ChangeEvent, useState } from 'react';
import TextInput from "../../UI/Inputs/TextInput/TextInput";
import Button from "../../UI/Button/Button";
import DateRangeInput from "../../UI/Inputs/DateRangeInput/DateRangeInput";

import "./styles/StatisticsFilters.sass";
import { IClientsListFilters } from "../../../store/Clients.recoil";
import { IDataRangeValue } from "../../UI/Inputs/DateRangeInput/types/DateRangeInput.types";
import classNames from "classnames";
import { IStatisticsFiltersProps } from "./types/StatisticsFilter.types";
import { StatisticType } from "../../../store/Statistics.recoil";

const StatisticsFilters = (props: IStatisticsFiltersProps) => {
    const { className, onFiltersChanged, statisticType } = props;

    const [values, setValues] = useState<IClientsListFilters>({
        query: '',
        startsAt: '',
        endsAt: ''
    })

    const onInputChanged = ( e: ChangeEvent<HTMLInputElement> ) => {
        setValues({
            ...values,
            query: e.target.value
        });
    }

    const onDataRangeChanged = ( dateRange: IDataRangeValue ) => {
        const { startsAt, endsAt } = dateRange;

        setValues({
            ...values,
            startsAt,
            endsAt
        })
    }

    const onSearchButtonClick = () => {
        emitFiltersChanged();
    }

    const onResetButtonClick = () => {
        setValues({
            query: '',
            startsAt: '',
            endsAt: ''
        });
        emitFiltersChanged();
    }

    const emitFiltersChanged = () => {
        if (typeof onFiltersChanged === "function") {
            onFiltersChanged({
                ...values,
            });
        }
    }

    return (
        <div className={classNames("statistics-filters", className)}>
            <TextInput
                className="filter filter-name"
                label={ statisticType === StatisticType.OWNER ? "Рекламная кампания" : "Клиент" }
                placeholder={ statisticType === StatisticType.OWNER ? "Введите название кампании" : "Введите название клиента" }
                value={values.query}
                onChange={onInputChanged}
            />

            <DateRangeInput
                className="filter filter-date"
                label="Период"
                startsAt={values.startsAt}
                endsAt={values.endsAt}
                onValuesChanged={onDataRangeChanged}
            />

            <div>
                <label>&nbsp;</label>
                <div className="statistics-filters__actions">
                    <Button isSecondary className="filter-button" title="Поиск" onClick={onSearchButtonClick} />
                    <Button isWhite className="filter-button" title="Сбросить" onClick={onResetButtonClick} />
                </div>
            </div>
        </div>
    )
}

export default StatisticsFilters;