import React from 'react';
import TargetingLocalities from "./components/TargetingLocalities/TargetingLocalities";
import Info from "../../../../UI/Info/Info";
import Chips from "../../../../UI/Chips/Chips";
import TargetingDevices from "./components/TargetingDevices/TargetingDevices";
import { ChipItemValue } from "../../../../UI/Chips/components/types/ChipsItem.types";
import { ICampaignFormTabTargetingProps } from "./types/CampaignFormTabTargeting.props";
import { useRecoilValue } from "recoil";
import {
    getCampaignTargetingAges,
    getCampaignTargetingDevices,
    getCampaignTargetingGenders,
    getCampaignTargetingGenres,
    getCampaignTargetingIntervals,
    getCampaignTargetingLocalities
} from "../../../../../store/Campaigns.recoil";
import { ICampaignFormData } from "../../types/CampaignForm.types";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import { DropdownItemValue } from "../../../../UI/Dropdown/types/Dropdown.types";

const CampaignFormTabTargeting = ( props: ICampaignFormTabTargetingProps ) => {
    const { values: formValues, onTabDataChanged } = props;

    const localities = useRecoilValue(getCampaignTargetingLocalities);
    const intervals = useRecoilValue(getCampaignTargetingIntervals);
    const genres = useRecoilValue(getCampaignTargetingGenres);
    const devices = useRecoilValue(getCampaignTargetingDevices);
    const ages = useRecoilValue(getCampaignTargetingAges);
    const genders = useRecoilValue(getCampaignTargetingGenders);

    const onRegionsItemsChanged = ( regions: number[] ) => {
        updateFormData({ ...formValues, regions });
    }

    const onIntervalsItemsChanged = ( values: DropdownItemValue[] ) => {
        updateFormData({
            ...formValues,
            intervals: values.map(( value ) => Number(value))
        });
    }

    const onGenresItemsChanged = ( values: ChipItemValue[] ) => {
        updateFormData({
            ...formValues,
            genres: values.map(( value ) => String(value))
        });
    }

    const onDeviceItemsChanged = ( devices: string[] ) => {
        updateFormData({
            ...formValues,
            devices
        });
    }

    const onAgesItemsChanged = ( values: DropdownItemValue[] ) => {
        updateFormData({
            ...formValues,
            age: values.map(( value ) => String(value))
        });
    }

    const onGendersItemsChanged = ( values: ChipItemValue[] ) => {
        updateFormData({
            ...formValues,
            sex: values.map(( value ) => Number(value))
        });
    }

    const updateFormData = ( formValues: ICampaignFormData ) => {
        if (typeof onTabDataChanged === "function") {
            onTabDataChanged({ ...formValues });
        }
    }

    return (
        <div className="campaign-create-form__fields">
            <div className="campaign-create-form__field">
                <TargetingLocalities
                    items={localities}
                    selectedValues={formValues.regions}
                    onSelectedValuesChanged={onRegionsItemsChanged}
                />
            </div>

            <div className="campaign-create-form__field">
                <Dropdown
                    label="Время показа рекламы"
                    placeholder="Выберите время показа рекламы"
                    items={intervals}
                    selectedValues={formValues.intervals}
                    onSelectedItemsChanged={onIntervalsItemsChanged}
                />

                <Info className="campaign-create-form__info">
                    Трансляция ведется в часовом поясе выбранных городов (по местному времени). В случае если Вы не
                    выбрали слоты по времени, реклама может быть показана в любой промежуток времени в указанные
                    даты.
                </Info>
            </div>

            <div className="campaign-create-form__field">
                <Chips
                    label="Выберите жанры телеканалов"
                    items={genres}
                    selectedValues={formValues.genres}
                    onSelectedItemsChanged={onGenresItemsChanged}
                />
                <Info className="campaign-create-form__info">
                    В случае если не выбран жанр контента кампании, то трансляция рекламы может производиться на
                    любых каналах, без учета жанра.
                </Info>
            </div>

            <div className="campaign-create-form__field">
                <TargetingDevices
                    label="Выберите тип рекламы"
                    selectedValues={formValues.devices}
                    onSelectedItemsChanged={onDeviceItemsChanged}
                />
            </div>

            <div className="campaign-create-form__field">
                <Dropdown
                    label="Выберите возраст"
                    placeholder="Выберите возраст"
                    items={ages}
                    selectedValues={formValues.age}
                    onSelectedItemsChanged={onAgesItemsChanged}
                />
            </div>

            <div className="campaign-create-form__field">
                <Chips
                    label="Выберите пол"
                    items={genders}
                    selectedValues={formValues.sex}
                    onSelectedItemsChanged={onGendersItemsChanged}
                />
            </div>
        </div>
    );
}

export default CampaignFormTabTargeting;