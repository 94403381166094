import axios from 'axios';
import { IApiPaymentsCreateRequest, IApiPaymentsListRequest, IApiPaymentsTransferRequest } from "./payments.requests";
import { IApiPaymentsListResponse } from "./payments.response";

class PaymentsApi {
    get = async (params?: IApiPaymentsListRequest): Promise<IApiPaymentsListResponse> => {
        const { data } = await axios.get(`/payments`, { params });

        return data;
    }

    create = async (params?: IApiPaymentsCreateRequest) => {
        const { data } = await axios.post(`/payments/create`, params);

        return data;
    }

    transfer = async (params: IApiPaymentsTransferRequest) => {
        const { data } = await axios.post(`/payments/transfer`, params);

        return data;
    }
}

const paymentsAPI = new PaymentsApi();
export default paymentsAPI;