import axios from 'axios';
import { IApiStatisticsGetRequest } from "./statistics.requests";
import { IApiStatisticsGetResponse } from "./statistics.response";

class StatisticsApi {
    get = async (params?: IApiStatisticsGetRequest): Promise<IApiStatisticsGetResponse> => {
        const { data } = await axios.get(`/statistics`, { params });

        return data;
    }

}

const statisticsAPI = new StatisticsApi();
export default statisticsAPI;