import React, { ChangeEvent, useState } from 'react';
import "./styles/ClientsFilters.sass";
import { IClientsFiltersProps } from "./types/ClientsFilter.types";
import TextInput from "../../UI/Inputs/TextInput/TextInput";
import Button from "../../UI/Button/Button";
import DateRangeInput from "../../UI/Inputs/DateRangeInput/DateRangeInput";
import classNames from "classnames";
import { IDataRangeValue } from "../../UI/Inputs/DateRangeInput/types/DateRangeInput.types";
import { IClientsListFilters } from "../../../store/Clients.recoil";

const ClientsFilters = ( props: IClientsFiltersProps ) => {
    const { className, onFiltersChanged } = props;

    const [values, setValues] = useState<IClientsListFilters>({
        query: '',
        startsAt: '',
        endsAt: ''
    })

    const onInputChanged = ( e: ChangeEvent<HTMLInputElement> ) => {
        setValues({
            ...values,
            query: e.target.value
        });
    }

    const onDataRangeChanged = ( dateRange: IDataRangeValue ) => {
        const { startsAt, endsAt } = dateRange;

        setValues({
            ...values,
            startsAt,
            endsAt
        })
    }

    const onButtonClick = () => {
        if (typeof onFiltersChanged === "function") {
            onFiltersChanged({
                ...values,
            })
        }
    }

    return (
        <div className={classNames("clients-filters", className)}>
            <TextInput
                className="filter filter-name"
                label="Клиент"
                placeholder="Введите клиента"
                value={values.query}
                onChange={onInputChanged}
            />

            <DateRangeInput
                className="filter filter-date"
                label="Период"
                startsAt={values.startsAt}
                endsAt={values.endsAt}
                onValuesChanged={onDataRangeChanged}
            />

            <div>
                <label>&nbsp;</label>
                <Button isSecondary className="filter-button" title="Поиск" onClick={onButtonClick} />
            </div>
        </div>
    )
}

export default ClientsFilters;