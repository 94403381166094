import React, { useCallback, useEffect, useState } from "react";
import "./styles/CampaignForm.sass";
import classNames from "classnames";
import Button from "../../UI/Button/Button";
import CampaignFormTabCommon from "./components/CampaignFormTabCommon/CampaignFormTabCommon";
import CampaignFormTabTargeting from "./components/CampaignFormTabTargeting/CampaignFormTabTargeting";
import CampaignFormTabBudget from "./components/CampaignFormTabBudget/CampaignFormTabBudget";
import { ICampaignFormData, ICampaignFormProps, ICampaignFormTab, TAB } from "./types/CampaignForm.types";
import API from "../../../api";
import { useSetRecoilState } from "recoil";
import { toastState } from "../../../store/Toast.recoil";
import { ToastType } from "../../UI/Toast/types/Toast.types";
import { useNavigate } from "react-router-dom";
import { campaignsState } from "../../../store/Campaigns.recoil";

const TABS: ICampaignFormTab[] = [
    {
        id: TAB.COMMON,
        title: '1. Основная информация',
        group: 'Основная информация'
    },
    {
        id: TAB.TARGETING,
        title: '2. Аудитория и таргетинг',
        group: 'Аудитория и таргетинг'
    },
    {
        id: TAB.BUDGET,
        title: '3. Бюджет и показы',
        group: 'Бюджет и показы'
    }
]

const CampaignForm = ( props: ICampaignFormProps ) => {
    const { campaignId } = props;

    const navigate = useNavigate();
    const [formData, setFormData] = useState<ICampaignFormData>({
        age: [],
        budgetDaily: 0,
        budgetTotal: 0,
        content: undefined,
        cost: 0.25,
        dailyImpressionsPerPerson: 0,
        devices: [],
        endsAt: "",
        genres: [],
        intervals: [],
        maxImpressions: 0,
        maxImpressionsPerDay: 0,
        name: "",
        regions: [],
        sex: [],
        startsAt: "",
        targetLink: "",
        kktuCodes: []
    });
    const [formErrors, setFormErrors] = useState<any>();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const setToast = useSetRecoilState(toastState);
    const setCampaignsState = useSetRecoilState(campaignsState);

    const onTabTitleClick = ( index: number ): void => {
        if (validateCommonTabData()) {
            setSelectedTabIndex(index);
        }
    }

    const onPrevButtonClick = (): void => {
        let prevTabIndex = selectedTabIndex - 1;
        setSelectedTabIndex(prevTabIndex);
    }

    const onNextButtonClick = (): void => {
        if (validateCommonTabData()) {
            let nextTabIndex = selectedTabIndex + 1;
            setSelectedTabIndex(nextTabIndex);
        }
    }

    const onFinishButtonClick = (): void => {
        if (validateBudgetTabData()) {
            submitForm();
        }
    }

    const onTabDataChanged = useCallback((data: ICampaignFormData) => {
        const newData = {
            ...formData,
            ...data
        }

        setFormData(newData);
    }, [formData, setFormData]);

    // TODO: подумать на счет функций валидации
    const validateCommonTabData = (): boolean => {
        const { name, startsAt, targetLink, content, kktuCodes } = formData;

        const formErrors = {
            name: "",
            startsAt: "",
            targetLink: "",
            content: "",
            kktuCodes: ""
        }

        if (typeof name === "undefined" || name.length === 0) {
            formErrors.name = 'Необходимо заполнить «Название».'
        }

        if (typeof startsAt === "undefined" || startsAt.length === 0) {
            formErrors.startsAt = 'Необходимо заполнить «Период действия рекламной кампании».'
        }

        const rx = new RegExp(/^[а-яйё\s]+$/, "i");
        if (!rx.test(name)) {
            formErrors.name = 'Поле "Название" может содержать только буквы.'
        }

        if (typeof targetLink === "undefined" || targetLink.length === 0) {
            formErrors.targetLink = 'Необходимо заполнить «Ссылка перехода».';
        }

        if (typeof kktuCodes === "undefined" || kktuCodes.length === 0) {
            formErrors.kktuCodes = 'Необходимо заполнить «Код справочника ККТУ».';
        }

        try {
            const url = new URL(targetLink);
            if (url.host.indexOf(".") === -1) {
                throw new Error();
            }
        } catch (e) {
            formErrors.targetLink = 'Поле «Ссылка перехода» не является правильным URL.';
        }

        if (!content || !content.duration || !content.size) {
            formErrors.content = 'Необходимо заполнить «Контент».';
        }

        setFormErrors(formErrors);

        return formErrors.name.length === 0 &&
            formErrors.startsAt.length === 0 &&
            formErrors.targetLink.length === 0 &&
            formErrors.content.length === 0 &&
            formErrors.kktuCodes.length === 0;
    }

    // TODO: подумать на счет функций валидации
    const validateBudgetTabData = (): boolean => {
        const { dailyImpressionsPerPerson, budgetDaily, budgetTotal } = formData;

        const formErrors = {
            dailyImpressionsPerPerson: "",
            budgetDaily: "",
            budgetTotal: ""
        }

        if (budgetDaily <= 0) {
            formErrors.budgetDaily = 'Значение «Бюджет в сутки» должно быть больше 0.'
        }

        if (budgetTotal <= 0) {
            formErrors.budgetTotal = 'Значение «Бюджет на кампанию» должно быть больше 0.'
        }

        if (dailyImpressionsPerPerson <= 0) {
            formErrors.dailyImpressionsPerPerson = 'Значение «Показов в сутки (на посетителя)» должно быть больше 0.'
        }

        setFormErrors(formErrors);

        return formErrors.budgetDaily.length === 0 &&
            formErrors.budgetTotal.length === 0 &&
            formErrors.dailyImpressionsPerPerson.length === 0;
    }

    const fetchCampaignsList = () => {
        API.campaigns.list()
            .then(( response ) => {
                const { count, limit, next, page, prev, results } = response;

                setCampaignsState(( state ) => ({
                    ...state,
                    list: {
                        prev,
                        next,
                        count,
                        page,
                        limit,
                        items: results,
                    }
                }))

            })
            .catch(( error ) => {
                console.log(error);
            })
    }

    const submitForm = () => {
        API.campaigns.save({
            ...formData,
            contentDuration: formData.content?.duration,
            contentSize: formData.content?.size,
        }, Number(campaignId))
            .then(( { data } ) => {
                if (formData.content?.file) {
                    API.campaigns.upload(data.id, formData.content.file)
                        .then(( response ) => {
                            fetchCampaignsList();
                        })
                }

                navigate('/campaigns');
            })
            .catch(( { response } ) => {
                const { data } = response;
                const { errors, message } = data;

                setFormErrors(errors);

                setToast({
                    type: ToastType.ERROR,
                    message,
                    isShown: true
                });
            })
    }

    useEffect(() => {
        if (campaignId) {
            API.campaigns.view(campaignId)
                .then(( { data } ) => {
                    setFormData({
                        name: data.name,
                        startsAt: data.startsAt,
                        endsAt: data.endsAt,
                        targetLink: data.targetLink,
                        content: data.content,

                        regions: data.regions || [],
                        intervals: data.intervals,
                        genres: data.genres || [],
                        devices: data.devices || [],
                        age: data.age || [],
                        sex: data.sex || [],
                        kktuCodes: data.kktuCodes || [],

                        budgetDaily: Number(data.budgetDaily),
                        budgetTotal: Number(data.budgetTotal),
                        maxImpressions: Number(data.maxImpressions),
                        maxImpressionsPerDay: Number(data.maxImpressionsPerDay),
                        dailyImpressionsPerPerson: Number(data.dailyImpressionsPerPerson),
                        cost: Number(data.cost),
                    });
                })
        }
    }, [campaignId]);

    return (
        <div className="campaign-create-form">
            <div className="campaign-create-form__tabs">
                {TABS.map(( tab, index ) => (
                    <div
                        key={tab.id}
                        className={classNames("campaign-create-form__tab", { 'active': index === selectedTabIndex })}
                        onClick={( e ) => onTabTitleClick(index)}
                    >{tab.title}</div>
                ))}
            </div>
            <div className="campaign-create-form__group">
                <div className="campaign-create-form__title">{TABS[selectedTabIndex].group}</div>

                {TABS[selectedTabIndex].id === TAB.COMMON && (
                    <CampaignFormTabCommon
                        values={formData}
                        errors={formErrors}
                        onTabDataChanged={onTabDataChanged}
                    />
                )}

                {TABS[selectedTabIndex].id === TAB.TARGETING && (
                    <CampaignFormTabTargeting
                        values={formData}
                        onTabDataChanged={onTabDataChanged}
                    />
                )}

                {TABS[selectedTabIndex].id === TAB.BUDGET && (
                    <CampaignFormTabBudget
                        values={formData}
                        errors={formErrors}
                        onTabDataChanged={onTabDataChanged}
                    />
                )}

            </div>
            <div className="campaign-create-form__actions">
                {selectedTabIndex > 0 && (<Button isSecondary title="Назад" onClick={onPrevButtonClick}/>)}
                {selectedTabIndex < 2 && (<Button title="Продолжить" onClick={onNextButtonClick}/>)}
                {selectedTabIndex === 2 && (<Button title="Завершить" onClick={onFinishButtonClick}/>)}
            </div>
        </div>
    )
}

export default CampaignForm;