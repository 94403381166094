import React, { ChangeEvent, useEffect, useState } from 'react';
import "./styles/TargetingLocalities.sass";
import TextInput from "../../../../../../UI/Inputs/TextInput/TextInput";
import { ITargetingLocalitiesProps } from "./types/TargetingLocalities.types";
import LocalityTargetingItem from "./components/LocalityTargetingItem/LocalityTargetingItem";
import classNames from "classnames";
import { toggleArrayItem } from "../../../../../../../helpers/array.helper";
import { ICampaignLocalityItem } from "../../../../../../../store/Campaigns.recoil";

const TargetingLocalities = ( props: ITargetingLocalitiesProps) => {
    const { items, selectedValues, onSelectedValuesChanged } = props;

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [expandedValues, setExpandedValues] = useState<number[]>([]);
    const [filteredItems, setFilteredItems] = useState<ICampaignLocalityItem[]>([]);

    const onSearchChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        setSearchQuery(query);

        if (query.length > 0) {
            const rx = new RegExp(query, "i");

            const flattedItems: ICampaignLocalityItem[] = [];
            const flatItems = (item: ICampaignLocalityItem) => {
                flattedItems.push({ id: item.id, text: item.text, nodes: [] });

                if (item.nodes && item.nodes.length > 0) {
                    for (const child of item.nodes) {
                        flatItems(child);
                    }
                }
            }
            flatItems(items[0]);

            setFilteredItems(
                flattedItems.filter((item: ICampaignLocalityItem) => rx.test(item.text))
            );
        } else {
            setFilteredItems(items);
        }
    }

    const onToggleItem = ( item: ICampaignLocalityItem ) => {
        setExpandedValues([
            ...toggleArrayItem(expandedValues, item.id)
        ]);
    }

    const onSelectItem = ( item: ICampaignLocalityItem ) => {
        let newSelectedValues = toggleArrayItem(selectedValues, item.id);
        const isSelected = newSelectedValues.includes(item.id);

        const setChildItemSelected = ( item: ICampaignLocalityItem, isSelected: boolean ) => {
            item.nodes.forEach(( item: ICampaignLocalityItem ) => {
                if (isSelected) {
                    if (!newSelectedValues.includes(item.id)) {
                        newSelectedValues.push(item.id);
                    }
                } else {
                    const i = newSelectedValues.indexOf(item.id);
                    if (i !== -1) {
                        newSelectedValues.splice(i, 1);
                    }
                }
                setChildItemSelected(item, isSelected);
            })
        }

        setChildItemSelected(item, isSelected);

        if (typeof onSelectedValuesChanged === "function") {
            onSelectedValuesChanged([
                ...newSelectedValues,
            ]);
        }
    }

    useEffect(() => {
        setFilteredItems(items);
    }, []);

    return (
        <div className={classNames("locality-targeting", { "is-search": searchQuery.length > 0 })}>
            <label className="locality-targeting__label" htmlFor="campaignform-regions">Выберите область, город</label>
            <TextInput
                className="locality-targeting__search"
                placeholder="Найти область, город"
                onChange={onSearchChanged}
            />

            <div className="locality-targeting__items">
                {filteredItems.map((item) => (
                    <LocalityTargetingItem
                        key={item.id}
                        item={item}
                        selectedValues={selectedValues}
                        expandedValues={expandedValues}
                        level={1}
                        onToggleItem={onToggleItem}
                        onSelectItem={onSelectItem}
                    />
                ))}
            </div>
        </div>
    );
}

export default TargetingLocalities;