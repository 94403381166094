import React from "react";
import "./styles/StatisticsList.sass";
import Grid from "../../UI/Grid/Grid";
import { IGridDataCellProps, IGridHeaderCellProps } from "../../UI/Grid/types/Grid.types";
import { IStatisticsListProps } from "./types/StatisticsList.types";
import { IStatisticsListItem, StatisticType } from "../../../store/Statistics.recoil";
import { statisticsDataCellFactory } from "./utils/factories";
import { prettifyMoneyValue } from "../../../helpers/prettifier.helpers";

const headers: IGridHeaderCellProps[] = [
    {
        title: 'Название'
    },
    {
        title: 'Дата'
    },
    {
        title: 'Переходы'
    },
    {
        title: 'Показы'
    },
    {
        title: 'Ставка'
    },
    {
        title: 'Сумма списаний'
    }
];

const StatisticsList = ( props: IStatisticsListProps ) => {
    const { statisticType = StatisticType.OWNER, list } = props;
    const { count, page, limit, items, isFetching = false } = list;

    const flattedItems = items.map((item) => ({
        ...item,
    }));

    const data = flattedItems
        .reduce(( cells: IGridDataCellProps[], item: IStatisticsListItem ): IGridDataCellProps[] => {
            return [
                ...cells,
                ...statisticsDataCellFactory(
                    item,
                    statisticType === StatisticType.OWNER ? ['company'] : [],
                    {
                        cost: (<div dangerouslySetInnerHTML={{__html: prettifyMoneyValue(item.cost) }}></div>),
                        amount: (<div dangerouslySetInnerHTML={{__html: prettifyMoneyValue(item.amount) }}></div>)
                    }
                )
            ];
        }, []);

    return (
        <div className="statistics-list">
            <Grid
                className="statistics-list__grid"
                headers={statisticType === StatisticType.OWNER ? headers : [ { title: 'Клиент' }, ...headers ] }
                data={data}
                page={page}
                limit={limit}
                count={count}
                isFetching={isFetching}
                columnsTemplate={statisticType === StatisticType.OWNER ? "3fr 1fr 1fr 1fr 1fr 1fr" : "1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr"}
            />
        </div>
    )
}

export default StatisticsList;