import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const DownloadIcon = (props: ISvgIconProps) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.10017 0.842087C6.10017 0.653157 6.24194 0.5 6.41682 0.5H9.5833C9.75818 0.5 9.89994 0.653157 9.89994 0.842087V7.34173C9.89994 7.53066 10.0417 7.68382 10.2166 7.68382H11.1114C11.3819 7.68382 11.5278 8.02663 11.3518 8.24853L8.24047 12.17C8.1141 12.3293 7.88601 12.3293 7.75964 12.17L4.64833 8.24853C4.47227 8.02663 4.61822 7.68382 4.88874 7.68382H5.78352C5.9584 7.68382 6.10017 7.53066 6.10017 7.34173L6.10017 0.842087ZM0.727273 11.0263C0.325611 11.0263 0 11.3764 0 11.8083V15.718C0 16.1499 0.325611 16.5 0.727273 16.5H15.2727C15.6744 16.5 16 16.1499 16 15.718V11.8083C16 11.3764 15.6744 11.0263 15.2727 11.0263H13.8182C13.4165 11.0263 13.0909 11.3764 13.0909 11.8083V12.5902C13.0909 13.0221 12.7653 13.3722 12.3636 13.3722H3.63636C3.2347 13.3722 2.90909 13.0221 2.90909 12.5902V11.8083C2.90909 11.3764 2.58348 11.0263 2.18182 11.0263H0.727273Z" fill="#0D81EC"/>
        </svg>
    );
};

export default DownloadIcon;