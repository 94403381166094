import React, { Suspense, useCallback, useEffect, useState } from 'react';
import "../styles/Page.sass";
import "./styles/StatisticsPage.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import StatisticsListEmpty
    from "../../../components/Statistics/StatisticsList/components/StatisticsListEmpty/StatisticsListEmpty";
import Switch from "../../../components/UI/Switch/Switch";
import { ISwitchItem } from "../../../components/UI/Switch/types/Switch.types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getClientsIsLoggedToClient } from "../../../store/Clients.recoil";
import StatisticsFilters from "../../../components/Statistics/StatisticsFilters/StatisticsFilters";
import {
    getStatisticsList,
    IStatisticsListFilters,
    statisticsState,
    StatisticType
} from "../../../store/Statistics.recoil";
import API from "../../../api";
import { getCampaignsList } from "../../../store/Campaigns.recoil";
import StatisticsList from "../../../components/Statistics/StatisticsList/StatisticsList";

const switchItems: ISwitchItem[] = [
    {
        id: StatisticType.OWNER,
        label: "Моя статистика"
    },
    {
        id: StatisticType.CLIENTS,
        label: "Статистика клиентов"
    }
]

const StatisticsPage = () => {
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const campaignsList = useRecoilValue(getCampaignsList);
    const statisticsList = useRecoilValue(getStatisticsList);
    const [statisticsType, setStatisticsType] = useState<string>(StatisticType.OWNER);

    const setStatisticsState = useSetRecoilState(statisticsState);

    const fetchStatistics = useCallback((filters?: IStatisticsListFilters) => {
        API.statistics.get({
            ...filters,
            type: statisticsType
        })
            .then(( response ) => {
                const { count, limit, next, page, prev, results } = response;

                setStatisticsState(( state ) => ({
                    ...state,
                    list: {
                        prev,
                        next,
                        count,
                        limit,
                        page,
                        items: results,
                    }
                }))
            })
            .catch(( err ) => {
                console.log(err);
            })
    }, [setStatisticsState, statisticsType]);

    const onFiltersChanged = ( filters?: IStatisticsListFilters ) => {
        fetchStatistics(filters);
    }

    const onTabChanged = ( item: ISwitchItem ) => {
        setStatisticsType(item.id);
    }

    useEffect(() => {
        setDocumentTitle('Статистика');
    }, []);

    useEffect(() => {
        fetchStatistics();
    }, [fetchStatistics]);

    return (
        <div className="page statistics-page">
            <PageTop className="statistics-page__top">
                <PageTitle
                    className="statistics-page__title"
                    title="Статистика"
                />

                {!isLoggedToClient && (
                    <div className="statistics-page__switch">
                        <Switch items={switchItems} onTabChanged={onTabChanged} />
                    </div>
                )}

                <StatisticsFilters
                    statisticType={statisticsType}
                    className="statistics-page__filters"
                    onFiltersChanged={onFiltersChanged}
                />
            </PageTop>

            <Suspense>
                {campaignsList.items.length === 0 && (<StatisticsListEmpty/>)}
                {campaignsList.items.length > 0 && (
                    <StatisticsList statisticType={statisticsType} list={statisticsList} />
                )}
            </Suspense>
        </div>
    );
}

export default StatisticsPage;
