import axios from "axios";
import campaignsAPI from "./services/campaigns/campaigns.api";
import clientAPI from "./services/clients/clients.api";
import companiesAPI from "./services/companies/companies.api";
import documentsAPI from "./services/documents/documents.api";
import paymentsAPI from "./services/payments/payments.api";
import partnerApi from "./services/partner/partner.api";
import profileAPI from "./services/profileAPI";
import statisticsAPI from "./services/statistics/statistics.api";
import suggestionAPI from "./services/suggestionAPI";
import usersAPI from "./services/users/users.api";

const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

class APIService {
    campaigns = campaignsAPI;
    companies = companiesAPI;
    documents = documentsAPI;
    clients = clientAPI;
    payments = paymentsAPI;
    partner = partnerApi;
    profile = profileAPI;
    statistics = statisticsAPI;
    suggestion = suggestionAPI;
    users = usersAPI;

    setToken = (token: string) => {
        axios.defaults.headers['Access-Token'] = `${token}`;
    };

    clearToken = () => {
        delete axios.defaults.headers['Access-Token'];
    };

    setExtraUser = (userId: number) => {
        axios.defaults.headers['X-User-Id'] = userId;
    };

    removeExtraUser = () => {
        delete axios.defaults.headers['X-User-Id'];
    }
}

const API = new APIService();
export default API;