import { atom, selector } from "recoil";
import { IPaginatedList } from "../models/types";
import { DEFAULT_LIST_ITEMS_COUNT } from "../models/consts";
import { IApiFile } from "../api/models/ApiFile";

export enum DocumentsType {
    OWNER = 'owner',
    CLIENTS = 'clients'
}

export enum DocumentsGroup {
    INVOICE = 'invoice',
    ACT = 'act'
}

export interface IDocumentsListItem {
    name: string;
    date: string;
    status: string;
    amount: number
    file: IApiFile;
}

export interface IDocumentsList extends IPaginatedList {
    items: IDocumentsListItem[]
}

export interface IDocumentsListFilters {
    type: string;
    group: string;
    query?: string;
    startsAt?: string;
    endsAt?: string;
    page?: number;
}

interface IDocumentsState {
    list: IDocumentsList;
}

export const documentsState = atom<IDocumentsState>({
    key: "documentsState",
    default: {
        list: {
            count: 0,
            page: 1,
            limit: DEFAULT_LIST_ITEMS_COUNT,
            items: [],
            isFetching: false,
        }
    },
});

export const getDocumentsList = selector<IDocumentsList>({
    key: 'documentsList',
    get: ( { get } ) => {
        return get(documentsState).list;
    },
})