import React, { useId, useRef } from 'react';
import './styles/FileInput.sass';
import { IFileInputProps } from "./types/FileInput.types";
import classNames from "classnames";
import Button from "../../Button/Button";

const FileInput = (props: IFileInputProps) => {
    const { label, placeholder, className, isRequired, text, hasError, errorMessage, onFileChanged, invalidate = '' } = props;

    const fileInputRef = useRef<HTMLInputElement>(null);
    const inputId = useId();

    const onButtonClick = () => {
        fileInputRef.current?.click();
    }

    const onFileInputChanged = (e: any) => {
        if (onFileChanged) {
            onFileChanged(e.target.files[0]);
        }
    }

    return (
        <div className={classNames("file-input__wrapper", className, { 'has-error': hasError })}>
            {label && (
                <label htmlFor={inputId} className={classNames("file-input__label", { 'is-required': isRequired })}>
                    {label}
                </label>
            )}

            <div className="file-input__hidden">
                <input id={inputId}
                       type="file"
                       onChange={onFileInputChanged}
                       ref={fileInputRef}
                       key={invalidate}
                       accept="video/*"
                />
            </div>

            <div className="file-input__content">
                <Button title={placeholder} onClick={onButtonClick} />
                {text && (
                    <div className="file-input__hint">
                        {text}
                    </div>
                )}
            </div>

            { hasError && errorMessage && (<div className="file-input__error">{errorMessage}</div>) }
        </div>
    )
}

export default FileInput;