import React, { useEffect, useState } from "react";
import "./styles/Switch.sass";
import { ISwitchProps } from "./types/Switch.types";
import classNames from "classnames";

const Switch = (props: ISwitchProps) => {
    const { className, items, onTabChanged } = props;

    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        if (typeof onTabChanged === "function") {
            onTabChanged(items[activeTab])
        }
    }, [items, activeTab, onTabChanged]);

    return (
        <div className={classNames('switch', className,)}>
            {items.map((item, i) => (
                <button
                    key={i}
                    className={classNames("switch__button", { 'active': activeTab === i })}
                    type="button"
                    onClick={() => {setActiveTab(i)}}
                >
                    {item.label}
                </button>
            ))}
        </div>
    );
};

export default Switch;