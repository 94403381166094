import React, { useCallback, useEffect, useState } from 'react';
import Grid from "../../UI/Grid/Grid";
import {
    IGridCollection,
    IGridDataCellProps,
    IGridHeaderCellProps
} from "../../UI/Grid/types/Grid.types";

import "./styles/FinancesHistory.sass";
import { IFinancesHistoryProps } from "./types/FinancesHistory.types";
import API from "../../../api";
import { financesHistoryDataCellFactory } from "./utils/factories";
import { prettifyMoneyValue } from "../../../helpers/prettifier.helpers";
import Switch from "../../UI/Switch/Switch";
import { ISwitchItem } from "../../UI/Switch/types/Switch.types";
import { IApiPaymentsListResponse } from "../../../api/services/payments/payments.response";
import FinancesHistoryCellDownload from "./components/FinancesHistoryCellDownload/FinancesHistoryCellDownload";

const switchItems: ISwitchItem[] = [
    {
        id: "debit",
        label: "Пополнения"
    },
    {
        id: "credit",
        label: "Списания"
    }
]

const headers: IGridHeaderCellProps[] = [
    {
        column: 'title',
        title: 'Номер'
    },
    {
        column: 'status',
        title: 'Статус'
    },
    {
        column: 'amount',
        title: 'Сумма'
    },
    {
        column: 'date',
        title: 'Дата'
    },
    {
        column: "actions",
        title: ""
    }
];

const FinancesHistory = ( props: IFinancesHistoryProps ) => {
    const [activeTab, setActiveTab] = useState<string>('debit');
    const [grid, setGrid] = useState<IGridCollection>({
        count: 25,
        page: 1,
        limit: 25,
        items: []
    });
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchPayments = useCallback((type: string) => {
        if (isFetching) return;

        setIsFetching(true);

        API.payments.get({ type })
            .then(( response: IApiPaymentsListResponse ) => {
                const { count, limit, next, page, prev, results } = response;
                const items = results.reduce((cells: IGridDataCellProps[], item: any) => {
                    return [
                        ...cells,
                        ...financesHistoryDataCellFactory(
                            item,
                            {
                                amount: (<div dangerouslySetInnerHTML={{__html: prettifyMoneyValue(item.amount) }}></div>),
                                file: (<>{item.file && (<FinancesHistoryCellDownload file={item.file} />)}</>)
                            }
                        )
                    ];
                }, []);

                setGrid({
                    ...grid,
                    items,
                    page,
                    limit,
                    count,
                });
            })
            .catch(( error ) => {
                console.log(error);
            })
            .finally(() => {
                setIsFetching(false);
            })
    }, [grid, isFetching]);

    useEffect(() => {
        fetchPayments(activeTab);
    }, [activeTab]);

    const onTabChanged = ( item: ISwitchItem ): void => {
        setActiveTab(item.id);
    }

    return (
        <>
            <div className="finances-history">
                <div className="finances-history__switch">
                    <Switch items={switchItems} onTabChanged={onTabChanged}/>
                </div>

                {grid.items.length > 0 && (
                    <Grid
                        className="finances-history__grid"
                        columnsTemplate="repeat(4, 1fr) 48px"
                        headers={headers}
                        data={grid.items}
                        page={grid.page}
                        limit={grid.limit}
                        count={grid.count}
                        isFetching={isFetching}
                    />
                )}
            </div>

            {grid.items.length === 0 && activeTab === 'debit' && (
                <div className="finances-history__empty">В данный момент пополнений нет</div>
            )}

            {grid.items.length === 0 && activeTab === 'credit' && (
                <div className="finances-history__empty">В данный момент списаний нет</div>
            )}
        </>
    );
}

export default FinancesHistory;
