import React, {useEffect, useId, useState} from 'react';
import classNames from "classnames";
import { ISearchInputProps } from "./types/SearchInput.types";

import './styles/SearchInput.sass';

const SearchInput = (props: ISearchInputProps) => {
    const {
        label,
        placeholder,
        className,
        isRequired,
        isReadonly,
        name,
        value,
        startResults = [],
        hasError,
        errorMessage,
        suggestionApi,
        suggestionRenderResult,
        suggestionClickResult
    } = props;

    const inputId = useId();

    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [results, setResults] = useState<any[]>([]);
    const [query, setQuery] = useState(value);

    const clearResults = (): void => {
        setResults([]);
    }

    const renderSuggestionResult = (item: any): string => {
        return typeof suggestionRenderResult === "function" ? suggestionRenderResult(item) : "";
    }

    const onSearchInputFocus = () => {
        setResults([ ...startResults ]);
    }

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const q = e.target.value;

        setQuery(q);

        if (q.length < 3) {
            setResults([]);
            return;
        }

        if (typeof suggestionApi !== "function") return;
        if (isFetching) return;

        setIsFetching(true);

        suggestionApi(q)
            .then(({ data }) => {
                setResults(data.data);
            })
            .catch((err) => {
                setResults([]);
            })
            .finally(() => {
                setIsFetching(false);
            })
    }

    const onSuggestionClickResult = (item: any): void => {
        if (typeof suggestionClickResult === "function") {
            suggestionClickResult(item);
        }

        clearResults();
    }

    useEffect(() => {
        setQuery(value);
    }, [value, setQuery]);

    return (
        <div className={classNames(
            "search-input__wrapper",
            className,
            { 'has-results': results.length > 0 },
            { 'has-error': hasError }
        )}>
            {label && (
                <label htmlFor={inputId} className={classNames("search-input__label", {'is-required': isRequired})}>
                    {label}
                </label>
            )}
            <div className="search-input__search">
                <input id={inputId}
                       name={name}
                       type="text"
                       className="search-input__input"
                       placeholder={placeholder}
                       value={query}
                       autoComplete="off"
                       onChange={onSearchInputChange}
                       onFocus={onSearchInputFocus}
                />
                { hasError && errorMessage && (<div className="search-input__error">{errorMessage}</div>) }
                {results.length > 0 && (
                    <div className="search-input__results">
                        {results.map((result: any, index) => (
                            <div
                                key={index}
                                className="search-input__result"
                                onClick={(e) => onSuggestionClickResult(result)}
                            >
                                {renderSuggestionResult(result)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchInput;