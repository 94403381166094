import React, { useEffect } from "react";
import "./styles/CampaignView.sass";
import { ICampaignViewProps } from "./types/CampaignView.types";
import CampaignViewDetails from "./components/CampaignViewDetails/CampaignViewDetails";
import CampaignViewStatistics from "./components/CampaignViewStatistics/CampaignViewStatistics";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getStatisticsList, statisticsState } from "../../../store/Statistics.recoil";
import API from "../../../api";

const CampaignView = ( props: ICampaignViewProps ) => {
    const { campaign } = props;

    const statisticsList = useRecoilValue(getStatisticsList);
    const setStatisticsState = useSetRecoilState(statisticsState);

    useEffect(() => {
        if (!campaign) { return }

        API.statistics.get({ campaignId: campaign.id })
            .then(( response ) => {
                const { count, limit, next, page, prev, results } = response;

                setStatisticsState(( state ) => ({
                    ...state,
                    list: {
                        prev,
                        next,
                        count,
                        limit,
                        page,
                        items: results,
                    }
                }))
            })
            .catch(( err ) => {
                console.log(err);
            })
    }, [campaign]);

    return (
        <div className="campaign-view">
            <CampaignViewDetails className="campaign-view__details" campaign={campaign} />
            {statisticsList.count > 0 && (
                <CampaignViewStatistics className="campaign-view__statistics" list={statisticsList} />
            )}
        </div>
    )
}

export default CampaignView;