import React, { ChangeEvent, useEffect, useState } from 'react';
import './styles/DateRangeInput.sass';
import classNames from "classnames";
import TextInput from "../TextInput/TextInput";
import { IDataRangeValue, IDateRangeInputProps } from "./types/DateRangeInput.types";


const DateRangeInput = (props: IDateRangeInputProps) => {
    const {
        label,
        className,
        startsAt = '',
        endsAt = '',
        hasError = false,
        errorMessage = undefined,
        onValuesChanged
    } = props;

    const [values, setValues] = useState<IDataRangeValue>({});

    const onValueChanged = ( e: ChangeEvent<HTMLInputElement> ) => {
        const newValues = {
            ...values,
            [e.target.name]: e.target.value
        };

        setValues(newValues);

        if (typeof onValuesChanged === "function") {
            onValuesChanged(newValues);
        }
    }

    useEffect(() => {
        setValues({
            startsAt,
            endsAt
        });
    }, [startsAt, endsAt]);

    return (
        <div className={classNames("data-range-input__wrapper", className, { 'has-error': hasError })}>
            {label && <label className="data-range-input__label">{label}</label>}
            <div className="data-range-input__inputs">
                <TextInput
                    className={classNames({ "is-empty": !values.startsAt })}
                    name="startsAt"
                    inputType="date"
                    placeholder="Дата начала"
                    value={startsAt}
                    onChange={onValueChanged}
                />

                <TextInput
                    className={classNames({ "is-empty": !values.endsAt })}
                    name="endsAt"
                    inputType="date"
                    placeholder="Дата окончания"
                    value={endsAt}
                    onChange={onValueChanged}
                />
            </div>
            { hasError && errorMessage && (<div className="text-input__error">{errorMessage}</div>) }
        </div>
    )
}

export default DateRangeInput;