import React, { Suspense, useEffect } from 'react';
import "../styles/Page.sass";
import "./styles/AccessPage.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import Switch from "../../../components/UI/Switch/Switch";
import { ISwitchItem } from "../../../components/UI/Switch/types/Switch.types";
import ClientsIcon from "../../../components/UI/SvgIcons/ClientsIcon";

const switchItems: ISwitchItem[] = [
    {
        id: "managers",
        label: "Менеджеры"
    },
    {
        id: "clients",
        label: "Клиенты"
    }
]

const AccessPage = () => {
    useEffect(() => {
        setDocumentTitle('Доступы');
    }, []);

    return (
        <div className="page access-page">
            <PageTop className="access-page__top">
                <PageTitle className="access-page__title" title="Доступы" />
            </PageTop>

            <Suspense>
                <div className="access-page__content">
                    <div className="access-page__managers">
                        <Switch items={switchItems} />
                    </div>
                    <div className="access-page__clients">
                        <div className="access-page__empty">
                            <ClientsIcon />
                            <div>Выберите менеджера или клиента</div>
                        </div>
                    </div>
                </div>
            </Suspense>
        </div>
    );
}

export default AccessPage;
