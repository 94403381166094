import React, { useState } from 'react';
import "./styles/Hint.sass";
import classNames from "classnames";
import { IHintProps } from "./types/Hint.types";
import InfoIcon from "../SvgIcons/InfoIcon";

const Hint = ( props: IHintProps) => {
    const { className, text } = props;

    const [isVisible, setIsVisible] = useState(false);

    const onIconClick = () => {
        setIsVisible(!isVisible);

        setTimeout(() => {
            setIsVisible(false);
        }, 3000)
    }

    return (
        <div className={classNames("hint", className)}>
            <button className="hint__trigger" onClick={() => onIconClick()}>
                <InfoIcon />
            </button>
            {isVisible && (
                <div className="hint__message">{text}</div>
            )}
        </div>
    );
}

export default Hint;
