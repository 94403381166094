import axios from 'axios';
import {
    IApiCampaignListResponse,
    IApiCampaignsAgesResponse,
    IApiCampaignsDevicesResponse,
    IApiCampaignsGendersResponse,
    IApiCampaignsGenresResponse,
    IApiCampaignsIntervalsResponse, IApiCampaignsKKTUCodesResponse,
    IApiCampaignsLocalitiesResponse,
    IApiCampaignViewResponse
} from "./campaigns.response";

class CampaignsApi {
    localities = async (): Promise<IApiCampaignsLocalitiesResponse> => {
        const response = await axios.get('/campaigns/localities');

        return response.data;
    }

    intervals = async (): Promise<IApiCampaignsIntervalsResponse> => {
        const response = await axios.get('/campaigns/intervals');

        return response.data;
    }

    genres = async (): Promise<IApiCampaignsGenresResponse> => {
        const response = await axios.get('/campaigns/genres');

        return response.data;
    }

    devices = async (): Promise<IApiCampaignsDevicesResponse> => {
        const response = await axios.get('/campaigns/devices');

        return response.data;
    }

    ages = async (): Promise<IApiCampaignsAgesResponse> => {
        const response = await axios.get('/campaigns/ages');

        return response.data;
    }

    genders = async (): Promise<IApiCampaignsGendersResponse> => {
        const response = await axios.get('/campaigns/genders');

        return response.data;
    }

    kktuCodes = async (): Promise<IApiCampaignsKKTUCodesResponse> => {
        const response = await axios.get('/campaigns/kktu-codes');

        return response.data;
    }

    save = async (values: any, campaignId?: number): Promise<any> => {
        let url = '/campaigns/save';

        if (campaignId) {
            url += `?c=${campaignId}`;
        }

        const response = await axios.post(url, values);

        return response.data;
    }

    upload = async (campaignId: number, file?: File): Promise<any> => {
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        }

        const response = await axios.post(`/campaigns/upload?c=${campaignId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    }

    list = async (): Promise<IApiCampaignListResponse> => {
        const response = await axios.get(`/campaigns`);

        return response.data;
    }

    view = async (id: string): Promise<IApiCampaignViewResponse> => {
        const response = await axios.get(`/campaigns/view?id=${id}`);

        return response.data;
    }

    moderate = async (campaignId: number): Promise<IApiCampaignViewResponse> => {
        const response = await axios.post(`/campaigns/moderate`, {
            campaignId
        });

        return response.data;
    }

    delete = async (campaignId: number): Promise<IApiCampaignViewResponse> => {
        const response = await axios.post(`/campaigns/delete`, {
            campaignId
        });

        return response.data;
    }

    pause = async (campaignId: number): Promise<IApiCampaignViewResponse> => {
        const response = await axios.post(`/campaigns/pause`, {
            campaignId
        });

        return response.data;
    }

    resume = async (campaignId: number): Promise<IApiCampaignViewResponse> => {
        const response = await axios.post(`/campaigns/resume`, {
            campaignId
        });

        return response.data;
    }

    cancel = async (campaignId: number): Promise<IApiCampaignViewResponse> => {
        const response = await axios.post(`/campaigns/cancel`, {
            campaignId
        });

        return response.data;
    }

    close = async (campaignId: number): Promise<IApiCampaignViewResponse> => {
        const response = await axios.post(`/campaigns/close`, {
            campaignId
        });

        return response.data;
    }
}

const campaignsAPI = new CampaignsApi();
export default campaignsAPI;