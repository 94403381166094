import React from 'react';
import "./styles/DocumentsList.sass";
import Grid from "../../UI/Grid/Grid";
import { IGridDataCellProps, IGridHeaderCellProps } from "../../UI/Grid/types/Grid.types";

import Switch from "../../UI/Switch/Switch";
import { ISwitchItem } from "../../UI/Switch/types/Switch.types";
import { IDocumentsListProps } from "./types/DocumentsList.types";
import { DocumentsGroup, DocumentsType, IDocumentsListItem } from "../../../store/Documents.recoil";
import { prettifyMoneyValue } from "../../../helpers/prettifier.helpers";
import { documentsDataCellFactory } from "./utils/factories";
import FinancesHistoryCellDownload
    from "../../Finances/FinancesHistory/components/FinancesHistoryCellDownload/FinancesHistoryCellDownload";

const switchItems: ISwitchItem[] = [
    {
        id: DocumentsGroup.INVOICE,
        label: "Счета"
    },
    {
        id: DocumentsGroup.ACT,
        label: "Акты"
    }
]

const headers: IGridHeaderCellProps[] = [
    {
        column: 'title',
        title: 'Номер'
    },
    {
        column: 'date',
        title: 'Дата'
    },
    {
        column: 'status',
        title: 'Статус'
    },
    {
        column: 'amount',
        title: 'Сумма'
    },
    {
        column: "actions",
        title: ""
    }
];

const DocumentsList = ( props: IDocumentsListProps ) => {
    const { list, documentsType = DocumentsType.OWNER, onGroupChanged } = props;
    const { count, page, limit, items, isFetching = false } = list;
    const flattedItems = items.map((item) => ({
        ...item,
    }));

    const data = flattedItems
        .reduce(( cells: IGridDataCellProps[], item: IDocumentsListItem ): IGridDataCellProps[] => {
            return [
                ...cells,
                ...documentsDataCellFactory(
                    item,
                    documentsType === DocumentsType.OWNER ? ['company'] : [],
                    {
                        amount: (<div dangerouslySetInnerHTML={{__html: prettifyMoneyValue(item.amount) }}></div>),
                        file: (<>{item.file && (<FinancesHistoryCellDownload file={item.file} />)}</>)
                    }
                )
            ];
        }, []);

    const onTabChanged = (item: ISwitchItem) => {
        onGroupChanged(item);
    }

    return (
        <div className="documents-list">
            <div className="documents-list__switch">
                <Switch items={switchItems} onTabChanged={onTabChanged} />
            </div>

            <Grid
                className="documents-list__grid"
                headers={documentsType === DocumentsType.OWNER ? headers : [ { title: 'Клиент' }, ...headers ] }
                data={data}
                page={page}
                limit={limit}
                count={count}
                isFetching={isFetching}
                columnsTemplate={documentsType === DocumentsType.OWNER ? "3fr 1fr 1fr 1fr 64px" : "1.5fr 1.5fr 1fr 1fr 1fr 64px"}
            />
        </div>
    )
}

export default DocumentsList;
