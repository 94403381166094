import React from 'react';
import "./styles/DropdownItem.sass";
import classNames from "classnames";
import Checkbox from "../../../Checkbox/Checkbox";
import { CheckboxState } from "../../../Checkbox/types/Checkbox.types";
import { IDropdownItemProps } from "./types/DropdownItem.types";

const DropdownItem = ( props: IDropdownItemProps ) => {
    const { item, isSelected, onItemClick } = props;

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (typeof onItemClick === "function") {
            onItemClick(item);
        }
    }

    return (
        <div className="dropdown-item" onClick={onClick}>
            <Checkbox className={classNames("dropdown-item__checkbox")}
                      state={isSelected ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
            />
            <div className="dropdown-item__label">{item.label}</div>
        </div>
    )
}

export default DropdownItem;