import React, { useEffect, useRef, useState } from "react";
import "./styles/ContentFileInput.sass";
import FileInput from "../../../../../../UI/Inputs/FileInput/FileInput";
import CrossIcon from "../../../../../../UI/SvgIcons/CrossIcon";
import { useSetRecoilState } from "recoil";
import { modalState, ModalType } from "../../../../../../../store/Modal.recoil";
import { IContentFileInputProps, IVideoSource } from "./types/ContentFileInput.types";
import classNames from "classnames";
import { getRandomString } from "../../../../../../../helpers/string.helper";

const ContentFileInput = (props: IContentFileInputProps) => {
    const { className, hasError, errorMessage, uploadContent, onFileUploaded, onFileRemoved } = props;

    const [videoSrc, setVideoSrc] = useState<IVideoSource | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const [fileInvalidator, setFileInvalidator] = useState<string>(getRandomString());

    const onFileChanged = ( file: File ): void => {
        setVideoPreview(file);

        if (typeof onFileUploaded === "function") {
            setTimeout(() => onFileUploaded(file, videoRef.current?.duration), 1500);
        }
    }

    const setVideoPreview = (file: File): void => {
        const fileURL = URL.createObjectURL(file);
        const fileReader = new FileReader();

        fileReader.onload = () => {
            setVideoSrc({
                name: file.name,
                url: fileReader.result as string,
            });

            URL.revokeObjectURL(fileURL);
        };

        fileReader.readAsDataURL(file);
    }

    const removeVideo = () => {
        setVideoSrc(null);
        setFileInvalidator(getRandomString());

        if (typeof onFileRemoved === "function") {
            onFileRemoved();
        }
    }

    const setModalState = useSetRecoilState(modalState);

    const showCampaignAdvertiserMarkModal = () => {
        setModalState({
            type: ModalType.campaignAdvertiseMark,
            isOpened: true
        })
    }

    const renderContentPlaceholder = (): string | React.ReactNode => {
        return (
            <div className="content-file-input__hint">
                В видеоролике обязательно должна быть&nbsp;
                <button type="button" onClick={showCampaignAdvertiserMarkModal}>маркировка о рекламодателе</button>.
            </div>
        )
    }

    useEffect(() => {
        if (uploadContent instanceof File) {
            setVideoPreview(uploadContent)
        } else if (uploadContent?.file instanceof File) {
            setVideoPreview(uploadContent.file)
        }
    }, [uploadContent]);

    return (
        <div className={classNames("content-file-input", className)}>
            <FileInput
                className="content-file-input__input"
                isRequired
                label="Контент"
                placeholder="Выберите видеоролик"
                text={renderContentPlaceholder()}
                onFileChanged={onFileChanged}
                hasError={hasError}
                invalidate={fileInvalidator}
                errorMessage={errorMessage}
            />

            {uploadContent && uploadContent.url && !videoSrc && (
                <div className="content-file-input__preview preview">
                    <div className="preview__wrapper">
                        <button type="button" className="preview__close" onClick={removeVideo}>
                            <CrossIcon/>
                        </button>

                        <video className="preview__video" controls playsInline ref={videoRef}>
                            <source src={uploadContent.url} type="video/mp4"/>
                        </video>
                    </div>
                    <div className="preview__description">{uploadContent.name}</div>
                </div>
            )}

            {videoSrc && (
                <div className="content-file-input__preview preview">
                    <div className="preview__wrapper">
                        <button type="button" className="preview__close" onClick={removeVideo}>
                            <CrossIcon/>
                        </button>

                        <video className="preview__video" controls playsInline ref={videoRef}>
                            <source src={videoSrc.url} type="video/mp4"/>
                        </video>
                    </div>
                    <div className="preview__description">{videoSrc.name}</div>
                </div>
            )}
        </div>
    );
}

export default ContentFileInput;