import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import "./styles/ProfileForm.sass";
import TextInput from "components/UI/Inputs/TextInput/TextInput";
import Button from "components/UI/Button/Button";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { companyState } from "../../../store/Company.recoil";
import { useNavigate } from "react-router-dom";
import { toastState } from "../../../store/Toast.recoil";
import { ToastType } from "../../UI/Toast/types/Toast.types";
import API from "../../../api";
import { IProfileSaveRequest } from "../../../api/services/requests/profileAPI.request";
import { userState } from "../../../store/User.recoil";
import { profileCompanyFactory, profilePersonFactory, profileState } from "../../../store/Profile.recoil";

const ProfileForm = () => {
    const navigate = useNavigate();

    const profile = useRecoilValue(profileState);

    const setUserState = useSetRecoilState(userState);
    const setCompanyState = useSetRecoilState(companyState);
    const setProfileState = useSetRecoilState(profileState);
    const setToast = useSetRecoilState(toastState);

    const [values, setValues] = useState<IProfileSaveRequest>({});
    const [errors, setErrors] = useState<IProfileSaveRequest>({});
    const [isValuesChanged, setIsValuesChanged] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const updateProfile = useCallback(() => {
        API.profile
            .update(values)
            .then(( { data } ) => {
                const { user, company } = data.data;

                setUserState(user);
                setCompanyState(company);
                setProfileState((state) => {
                    return {
                        ...state,
                        person: profilePersonFactory(user),
                        company: profileCompanyFactory(company),
                    }
                });

                setToast({
                    type: ToastType.SUCCESS,
                    message: "Данные сохранены успешно",
                    isShown: true
                })
            })
            .catch(({ response }) => {
                const { data } = response;
                const { errors } = data;

                setErrors(errors);
                setIsLoading(false);
                setToast({
                    type: ToastType.ERROR,
                    message: "Ошибка при сохранении формы",
                    isShown: true
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [setUserState, setCompanyState, setProfileState, setToast, values]);

    const onValueChanged = ( e: ChangeEvent<HTMLInputElement> ) => {
        const newValues = {
            ...values,
            [e.target.name]: e.target.value
        }

        setValues(newValues);
        setIsValuesChanged(true);
    }

    const onCancelButtonClick = () => {
        navigate("/clients");
    }

    const onSaveButtonClick = ( e: any ) => {
        if (isLoading) return;

        setIsLoading(true);

        updateProfile();
    }

    useEffect(() => {
        setValues({
            kpp: profile.company?.kpp,
            businessArea: profile.company?.businessArea,
            website: profile.company?.website,
            address: profile.company?.address,
            firstName: profile.person?.firstName,
            middleName: profile.person?.middleName,
            lastName: profile.person?.lastName,
            phone: profile.person?.phone,
        });
    }, [profile]);

    return (
        <div className="profile-form__wrapper">
            <div className="profile-form">
                <div className="profile-form__form form">
                    <div className="form__row">
                        <TextInput
                            name="lastName"
                            label="Фамилия"
                            placeholder="Введите фамилию"
                            value={profile.person?.lastName}
                            onChange={onValueChanged}
                            hasError={!!errors.lastName}
                            errorMessage={errors.lastName}
                        />

                        <TextInput
                            isReadOnly
                            name="email"
                            label="E-mail"
                            value={profile.person?.email}
                        />
                    </div>
                    <div className="form__row">
                        <TextInput
                            name="firstName"
                            label="Имя"
                            placeholder="Введите имя"
                            value={profile.person?.firstName}
                            onChange={onValueChanged}
                            hasError={!!errors.firstName}
                            errorMessage={errors.firstName}
                        />

                        <TextInput
                            name="phone"
                            label="Телефон"
                            placeholder="Введите номер телефона"
                            value={values.phone}
                            onChange={onValueChanged}
                            hasError={!!errors.phone}
                            errorMessage={errors.phone}
                        />
                    </div>
                    <div className="form__row">
                        <TextInput
                            name="middleName"
                            label="Отчество"
                            placeholder="Введите отчество"
                            value={profile.person?.middleName}
                            onChange={onValueChanged}
                            hasError={!!errors.middleName}
                            errorMessage={errors.middleName}
                        />

                        <TextInput
                            isReadOnly
                            isRequired
                            label="ИНН"
                            value={profile.company?.inn}
                        />
                    </div>

                    <div className="form__row">
                        <TextInput
                            name="businessArea"
                            label="Сфера деятельности"
                            value={values.businessArea}
                            onChange={onValueChanged}
                            hasError={!!errors.businessArea}
                            errorMessage={errors.businessArea}
                        />
                    </div>
                </div>
            </div>

            <div className="profile-form__actions">
                <Button isWhite title="Отмена" onClick={onCancelButtonClick}/>
                <Button title="Сохранить" onClick={onSaveButtonClick} isDisabled={!isValuesChanged || isLoading}/>
            </div>
        </div>
    );
}

export default ProfileForm;
