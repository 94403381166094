import React from "react";
import "./styles/CampaignViewStatistics.sass";
import classNames from "classnames";
import { ICampaignViewStatisticsProps } from "./types/CampaignViewStatistics.types";
import StatisticsList from "../../../../Statistics/StatisticsList/StatisticsList";

const CampaignViewStatistics = ( props: ICampaignViewStatisticsProps ) => {
    const { className, list } = props;

    return (
        <div className={classNames("campaign-view-statistics", className)}>
            <div className="campaign-view-statistics__title">Статистика</div>
            {list.items.length > 0 && (<StatisticsList list={list} />)}
        </div>
    )
}

export default CampaignViewStatistics;