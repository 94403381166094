import React, { useEffect } from 'react';
import "../styles/Page.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import CampaignFilters from "../../../components/Campaign/CampaignsFilters/CampaignFilters";
import CampaignsList from "../../../components/Campaign/CampaignsList/CampaignsList";
import { useNavigate } from "react-router-dom";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import { useRecoilValue } from "recoil";
import { getClientsIsLoggedToClient } from "../../../store/Clients.recoil";
import { getCampaignsList } from "../../../store/Campaigns.recoil";

const CampaignsPage = () => {
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const campaignsList = useRecoilValue(getCampaignsList);

    const navigate = useNavigate();
    const onButtonClick = () => {
        navigate("/campaigns/create");
    }

    useEffect(() => {
        setDocumentTitle(isLoggedToClient ? "Рекламные кампании" : "Мои рекламные кампании");
    }, [isLoggedToClient]);

    return (
        <div className="page campaigns">
            <PageTop className="campaigns__top">
                <PageTitle
                    className="campaigns__title"
                    title={isLoggedToClient ? "Рекламные кампании" : "Мои рекламные кампании"}
                    hasButton
                    buttonTitle="Создать рекламную кампанию"
                    buttonAction={onButtonClick}
                />

                {false && (<CampaignFilters />)}
            </PageTop>

            <CampaignsList items={campaignsList.items} />
        </div>
    );
}

export default CampaignsPage;
