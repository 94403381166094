import { atom, selector } from "recoil";

interface IProfileCompany {
    legalName: string;
    email: string;
    inn: string;
    address: string;
    businessArea?: string;
    website?: string;
    kpp?: string;
    companyForm?: string;
}

interface IProfilePerson {
    firstName?: string,
    middleName?: string,
    lastName?: string,
    phone: string;
    email: string;
    balance: number;
    clientsBalance?: number,
    usedBalance?: number,
}

export interface IProfileState {
    company?: IProfileCompany;
    person?: IProfilePerson;
}

export const profileState = atom<IProfileState>({
    key: "profileState",
    default: {
    }
});

export const getProfilePersonBalance = selector<number>({
    key: "profilePersonBalance",
    get: ({ get }) => {
        const person = get(profileState).person;

        return person?.balance || 0;
    }
})

export const getProfilePersonClientsBalance = selector<number>({
    key: "profilePersonClientsBalance",
    get: ({ get }) => {
        const person = get(profileState).person;

        return person?.clientsBalance || 0;
    }
})

export const getProfilePersonUsedBalance = selector<number>({
    key: "profilePersonUsedBalance",
    get: ({ get }) => {
        const person = get(profileState).person;

        return person?.usedBalance || 0;
    }
})

export const getProfilePersonTotalBalance = selector<number>({
    key: "profilePersonTotalBalance",
    get: ({ get }) => {
        const person = get(profileState).person;

        return (person?.balance || 0) + (person?.clientsBalance || 0);
    }
})

export const profileCompanyFactory = (data: any): IProfileCompany => {
    const { companyForm, legalName, email, inn, address, businessArea, website, kpp } = data;

    return {
        companyForm,
        legalName,
        email,
        inn,
        address,
        businessArea,
        website,
        kpp
    }
}

export const profilePersonFactory = (data: any): IProfilePerson => {
    const { firstName, middleName, lastName, phone, email, balance, usedBalance, clientsBalance } = data;

    return {
        firstName,
        middleName,
        lastName,
        phone,
        email,
        balance: Number(balance),
        usedBalance: Number(usedBalance),
        clientsBalance: Number(clientsBalance)
    }
}